document.addEventListener("DOMContentLoaded", (event) => {
    //the scripts you need on the first screen.

    let scriptLaunchStart = true;
    //this function is called once, after a user interaction.
    const scriptLaunch = () => {
        //== Start add burger-menu ==//
        const burgerBtn = document.querySelector('.header__burger')
        const html = document.querySelector('html')
        const menuItems = document.querySelectorAll('.menu-heeder-menu-container a')
        const burgerMenu = document.querySelector('.header__main')
        menuItems.forEach(item => {
            item.addEventListener('click', () => {
                burgerMenu.classList.remove('open')
                burgerBtn.classList.remove('open')
                changeHtmlOverflow('remove')
            })
        })

        burgerBtn.addEventListener('click', () => {
            burgerMenu.classList.toggle('open')
            burgerBtn.classList.toggle('open')

            if (burgerMenu.classList.contains('open')) {
                changeHtmlOverflow('add')
            } else {
                changeHtmlOverflow('remove')
            }
        })
        //== End add burger-menu ==//


        const changeHtmlOverflow = (action) => {
            if (action === 'add') {
                html.style.overflow = 'hidden'
            }
            if (action === 'remove') {
                html.style.overflow = 'inherit'
            }
        }

        const header = document.querySelector('.header')
        let prevScrollpos = window.pageYOffset;

        window.addEventListener('scroll', () => {
            const currentScrollPos = window.pageYOffset;

            if (prevScrollpos <= currentScrollPos) {
                header.style.top = "-90px";
            } else {
                header.style.top = "15px";
            }

            prevScrollpos = currentScrollPos;
        })



    }
    if (window.pageYOffset >= 1 && scriptLaunchStart) {
        scriptLaunchStart = false;
        scriptLaunch();
    } else if (window.pageYOffset < 1 && scriptLaunchStart) {
        const events = ['mousemove', 'click', 'mousewheel', 'scroll', 'touchstart', 'keydown', 'keypress', 'mouseover', 'mouseout', 'resize'];
        events.forEach(event => {
            window.addEventListener(event, () => {
                if (scriptLaunchStart) {
                    scriptLaunchStart = false;
                    scriptLaunch();
                }
            });
        });
    }
});
